import React from 'react';
import ReactModal from 'react-modal';
import { createGlobalStyle, css } from 'styled-components';
import { ieHideScroll, media } from '@peloton/styles';

export const TIMEOUT_DURATION = 300;
export const FLYOUT_CLASS = 'flyout-content';

export type Props = {
  children: JSX.Element;
  isOpen: boolean;
  backgroundColor: string;
  ariaLabel: string;
  handleRequestClose?(): void;
  shouldReturnFocusAfterClose?: boolean;
  handleAfterOpen?(): void;
  direction?: 'left' | 'right';
  className?: string;
  hiddenElementId?: string;
};

export const Flyout = ({
  children,
  isOpen,
  backgroundColor,
  handleRequestClose,
  ariaLabel,
  handleAfterOpen,
  shouldReturnFocusAfterClose = true,
  className,
  hiddenElementId = 'root',
  direction = 'right',
}: Props) => {
  React.useEffect(() => {
    // http://reactcommunity.org/react-modal/accessibility/#app-element
    ReactModal.setAppElement(document.getElementById(hiddenElementId) ?? document.body);
  }, [hiddenElementId]);
  return (
    <ReactModal
      className={`${FLYOUT_CLASS} ${className}`}
      overlayClassName="flyout-overlay"
      onRequestClose={handleRequestClose}
      onAfterOpen={handleAfterOpen}
      isOpen={isOpen}
      closeTimeoutMS={TIMEOUT_DURATION}
      contentLabel={ariaLabel}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
    >
      <GlobalModalStyles direction={direction} backgroundColor={backgroundColor} />
      {children}
    </ReactModal>
  );
};

const FlyoutLeft = css`
  .${FLYOUT_CLASS} {
    left: 0;
    transform: translate3d(-100%, 0, 0);
  }

  .ReactModal__Content--after-open {
    transform: translate3d(0, 0, 0);
  }
  .ReactModal__Content--before-close {
    transform: translate3d(-100%, 0, 0);
  }
`;
const FlyoutRight = css`
  .${FLYOUT_CLASS} {
    right: 0;
    transform: translate3d(100%, 0, 0);
  }

  .ReactModal__Content--after-open {
    transform: translate3d(0, 0, 0);
  }
  .ReactModal__Content--before-close {
    transform: translate3d(100%, 0, 0);
  }
`;
const GlobalModalStyles = createGlobalStyle<{
  backgroundColor: string;
  direction: 'left' | 'right';
}>`
  .${FLYOUT_CLASS} {
    position: fixed;
    background: ${props => props.backgroundColor};
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    top: 0;
    bottom: 0;
    border: none;
    transition: 300ms ease-out;
    z-index: 90005;

    ${ieHideScroll}

    ${media.tablet`
    transition: 400ms ease;
    `}
  }

  ${({ direction }) => (direction === 'left' ? FlyoutLeft : FlyoutRight)}

  .flyout-overlay {
    &::before {
      content: "";

      /*
        safari specific hack for animating pseudoelements
        see https://stackoverflow.com/questions/16348489/is-there-a-css-hack-for-safari-only-not-chrome
      */
      @media not all and (min-resolution:.001dpcm) {
        @media {
          content: none;
        }
      }

      position: fixed;
      z-index: 90005;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0);
      /*  
        moving from opacity transition to alpha value keyframes to address https://pelotoncycle.atlassian.net/browse/WE-7734
      */
      @keyframes flyout-fadein {
        from {
         background-color: rgba(0,0,0,0);
        }

        to {
          background-color: rgba(0,0,0,0.3);
        }
      }
    }


  }

  .ReactModal__Overlay--after-open{
    &::before {
      animation: flyout-fadein 500ms ease forwards;

      /*
        safari specific hack for animating pseudoelements
        see https://stackoverflow.com/questions/16348489/is-there-a-css-hack-for-safari-only-not-chrome
      */
      @media not all and (min-resolution:.001dpcm) {
        @media {
          content: "";
        }
      }
    }
  }

  .ReactModal__Overlay--before-close{
    &::before {
      background-color: rgba(0,0,0,0.3);
      @keyframes flyout-fadeout {
        from {
          background-color: rgba(0,0,0,0.3);
        }
        
        to {
          background-color: rgba(0,0,0,0);
        }
      }
      animation: flyout-fadeout 500ms ease forwards;
    }
  }

  .ReactModal__Body--open {
    overflow-y: hidden;
  }
`;
