import type { CacheResolver } from 'apollo-boost/lib/index';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-boost/lib/index';
import { keys } from 'ramda';
import type { Locale } from '@peloton/internationalize';
import { CONTENTFUL_TOKEN } from '@ecomm/contentful';
import { URL, QUERY_TYPENAME_MAP } from './config';
import { CopyQuery } from './documents.generated';
import data from './static';

const introspectionQueryResultData = require('./fragmentTypes.json');

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const toResolver = (__typename: string): CacheResolver => (_, { id }, { getCacheKey }) =>
  getCacheKey({ __typename, key: id });

// Since we don't have a good way of unwrapping/normalizing/querying Contentful Ids (and are instead using `key`)
// toQuery/toResolver tell Apollo *how* to store the data in-cache, and, by extension, make it possible to
// query data by key
//
// More info/cookbook: https://www.apollographql.com/docs/react/advanced/caching/#cache-redirects-with-cacheredirects
const toQuery = (queryMap: Record<string, string>) =>
  keys(queryMap).reduce((query, key) => {
    query[key] = toResolver(queryMap[key]);
    return query;
  }, {});

export type CopyFile = {
  data: Record<string, { items: Record<string, KeyValueType> }>;
};

type KeyValueType = { key: string; value: string; __typename: string };

type Options = {
  locale: Locale;
  preview: boolean;
  tag: string;
};

// returns a new Apollo Client, hydrated with localized strings
export const toClient = ({ locale, preview, tag }: Options) => {
  const link = new HttpLink({
    uri: URL,
    headers: {
      Authorization: `Bearer ${CONTENTFUL_TOKEN}`,
    },
  });

  // Stores each record in an object with the key as its type: key or url or an
  // incremented number, e.g. StudioHero:pgHome.homeHero or StudioHeroCollection:1
  // The incremented numbers are for collections and differentiate them from one another
  const cache = new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject: ((i = 0) => ({ key, url, __typename }: any) =>
      `${__typename}:${key || url || i++}`)(),
    cacheRedirects: {
      Query: toQuery(QUERY_TYPENAME_MAP),
    },
  });

  const client = new ApolloClient({
    cache,
    link,
    defaultOptions: {
      query: {
        variables: {
          locale,
          preview,
          tag,
        },
      },
    },
  });

  client.writeQuery({
    query: CopyQuery,
    data: data[locale].data,
  });

  // Adds a function to the window that allows us to print the cached keys and their data
  // for debugging purposes
  (window as any).__printCopy = () => client.extract();

  return client;
};
