import type { DocumentNode } from 'graphql';
import { pathOr } from 'ramda';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import type { QueryHookOptionsV2 as TQueryHookOptions } from '@peloton/graphql/apolloV2';
import { useQueryV2 as useBaseQuery } from '@peloton/graphql/apolloV2';
import processContentfulData from '@ecomm/copy-xray/processContentfulData';
import { getIsToggleActive } from '@studio/feature-toggles';
import Context from './context';
export { QueryHookResultV2 as QueryResult } from '@peloton/graphql/apolloV2';

export type QueryHookOptions<TData, TVariables, TCache = object> = TQueryHookOptions<
  TVariables,
  TCache
>;

export const useQuery = <TData, TVariables>(query: DocumentNode, options?: any) => {
  const copyXrayEnabled = useSelector(getIsToggleActive('copyXray'));

  const client = useContext(Context);
  const { data, ...rest } = useBaseQuery<TData, TVariables>(query, {
    client,
    overrideClient: client,
    fetchPolicy: 'cache-only',
    ...options,
    variables: {
      ...pathOr({}, ['defaultOptions', 'query', 'variables'], client),
      ...(options.variables || {}),
    },
  });

  return {
    data: copyXrayEnabled ? processContentfulData(data as object) : data,
    ...rest,
  };
};

// These hooks are available in @apollo/react-hooks (and the upcoming @apollo/client 3.0)

export const useLazyQuery = <TData, TVariables>(_: DocumentNode, __?: any) => {
  console.error(
    '@ecomm/copy needs to upgrade to @apollo/react-hooks in order to use this feature',
  );
};

export type LazyQueryHookOptions<TVariables, TCache = object> = TQueryHookOptions<
  TVariables,
  TCache
>;
