import { brand, white } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Footer } from '@studio/footer';
import { HeaderBanner, HeaderNav } from '@studio/header';

export type LayoutProps = {
  background?: 'white' | 'offWhite';
  showFooter?: boolean;
};

export const LayoutView: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  background = 'offWhite',
  showFooter = true,
  children,
  ...props
}) => {
  return (
    <Container background={background}>
      <HeaderBanner />
      <HeaderNav />
      <MainContent id="main-content" {...props}>
        {children}
      </MainContent>
      {showFooter && <Footer />}
    </Container>
  );
};

const Container = styled.div`
  background: ${({ background }: LayoutProps) =>
    background === 'white' ? white : brand.light};
  display: grid;
  width: 100%;
  height: 100%;
  -ms-grid-rows: auto auto 1fr;
  grid-template-rows: auto auto 1fr;
  min-height: 100%;
`;

const MainContent = styled.main`
  background: ${brand.light};
`;

export default LayoutView;
