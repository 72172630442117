import { useEffect, useState } from 'react';
import type { Language } from '@peloton/internationalize/models/language';
import { isLocatedInProtectedState } from './utils';

type OptionsParam = {
  deleteSelectorContent?: boolean;
  makeSelectorVisible?: boolean;
  makeElementsVisible?: string[];
  deleteElements?: string[];
} | null;

declare global {
  interface Window {
    OneTrust?: {
      ToggleInfoDisplay: () => void;
      Close: () => void;
      IsAlertBoxClosed: () => boolean;
      GetDomainData: () => { ConsentModel: { Name: string } };
      getGeolocationData: () => { country: string; state: string };
      changeLanguage: (language: Language) => void;
      InsertHtml: (
        element: string,
        selector: string,
        callback: (() => void) | null,
        options: OptionsParam,
        groupId: string,
      ) => void;
    };
    OptanonWrapper: () => void;
  }
}

type OneTrustHook = {
  isReady: boolean;
  toggleInfoDisplay: () => void;
  getConsentModelType: () => string | undefined;
  getGeolocationData: () => { country: string; state: string } | undefined;
  getIsAlertBoxClosed: () => boolean | undefined;
  isDoNotSellEnabled: boolean;
  doNotSellLink: string;
  insertHTML: (
    element: string,
    selector: string,
    callback: (() => void) | null,
    options: OptionsParam,
    groupId: string,
  ) => void;
};

export const useOneTrust = (): OneTrustHook => {
  const [isReady, setReady] = useState<boolean>(false);

  useEffect(() => {
    if (window.hasOwnProperty('OneTrust')) {
      setReady(true);
    }
  }, []);

  useEffect(() => {
    // runs on page reload
    // OneTrust SDK looks for a function with this name on the window and calls it
    window.OptanonWrapper = () => {
      if (window.hasOwnProperty('OneTrust')) {
        setReady(true);
      }
    };
  }, []);

  const insertHTML = (
    element: string,
    selector: string,
    callback: (() => void) | null,
    options: OptionsParam,
    groupId: string,
  ) => {
    window?.OneTrust?.InsertHtml(element, selector, callback, options, groupId);
  };

  const toggleInfoDisplay = () => {
    window?.OneTrust?.ToggleInfoDisplay();
  };

  const getConsentModelType = () => window?.OneTrust?.GetDomainData()?.ConsentModel.Name;

  const getGeolocationData = () => window?.OneTrust?.getGeolocationData();

  const getIsAlertBoxClosed = () => window?.OneTrust?.IsAlertBoxClosed();

  const shouldShowDoNotSell = () => {
    if (typeof window !== 'undefined' && isReady) {
      const memberLocation = getGeolocationData();
      return isLocatedInProtectedState({
        country: memberLocation?.country || '',
        state: memberLocation?.state || '',
      });
    }
    return false;
  };

  return {
    isReady,
    toggleInfoDisplay,
    getConsentModelType,
    getGeolocationData,
    getIsAlertBoxClosed,
    isDoNotSellEnabled: shouldShowDoNotSell(),
    doNotSellLink:
      'https://privacyportal.onetrust.com/webform/18f92a28-d2ae-4a6a-8f99-85b4455e22c0/0b31d071-cc72-404b-84d3-6601efbbba18',
    insertHTML,
  };
};
