import { Auth0Client } from '@auth0/auth0-spa-js';
import type {
  Auth0ClientOptions,
  RedirectLoginResult,
  LogoutOptions,
} from '@auth0/auth0-spa-js';
import type { AxiosResponse } from 'axios';
import React from 'react';
import { toMatchingTld, SupportedTLD } from '@peloton/internationalize/models/locale';
import authClient, { AuthEnv } from './authClient';
import { getContainsLoginCookie } from './helpers';

// login errors from auth0 login error description when redirecting to app - see queryParams.error_description;
export const USER_EXISTS_LOGIN_ERROR_CODE = '10501';
export const GOOGLE_LOGIN_ERROR_CODE = '10502';
export const APPLE_LOGIN_ERROR_CODE = '10503';
export const USER_DOES_NOT_EXIST_LOGIN_ERROR_CODE = '20101';
export const loginErrors = new RegExp(
  `${USER_EXISTS_LOGIN_ERROR_CODE}|${GOOGLE_LOGIN_ERROR_CODE}|${APPLE_LOGIN_ERROR_CODE}|${USER_DOES_NOT_EXIST_LOGIN_ERROR_CODE}`,
);
const userIdKey = 'http://onepeloton.com/user_id'; // this comes from auth0 IdToken

type Auth0ClientOverride = Pick<Auth0Client, 'loginWithRedirect'> & {
  isAuthenticated: boolean;
  userId: string;
  handleRedirectCallback: () => Promise<void>;
  getAccessTokenSilently: Auth0Client['getTokenSilently'];
  // Allow for returning a promise that doesnt resolve if the Oauth client isn't ready yet
  getIsAuthenticated: () => Promise<unknown>;
  getIdTokenClaims: Auth0Client['getIdTokenClaims'];
  isLoading: boolean;
  error: any;
  logout: (p: LogoutOptions, b?: boolean) => Promise<void> | void;
  checkSession: () => Promise<AxiosResponse>;
  setLoginError?: (error: string) => void;
  getLoginError?: () => string;
};

const Auth0Context = React.createContext<Auth0ClientOverride>({
  isAuthenticated: false,
  userId: '',
  isLoading: true,
  handleRedirectCallback: () => Promise.resolve(),
  getAccessTokenSilently: () => Promise.resolve() as any,
  getIsAuthenticated: () => Promise.resolve(false),
  getIdTokenClaims: () => Promise.resolve({ __raw: '-1' }),
  loginWithRedirect: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  checkSession: () => Promise.resolve({ status: 400, data: null } as AxiosResponse),
  error: null,
});
export const useOauth = () => React.useContext(Auth0Context);

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export type OauthProviderProps = Auth0ClientOptions & {
  onRedirectCallback?: (r: RedirectLoginResult['appState']) => Promise<void>;
  authEnv?: AuthEnv;
  onErrorCallback?: (e: any, action: string) => void;
  shouldUseRedirectPrevention?: boolean;
};

export const getAuth0Client = (initOptions: Auth0ClientOptions) => {
  const tld = toMatchingTld(window.location.hostname);
  return new Auth0Client({
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
    // If a client is misconfigured in the auth0 tenant it can take 60 seconds for getTokenSilently to resolve
    authorizeTimeoutInSeconds: initOptions?.authorizeTimeoutInSeconds ?? 15,
    cookieDomain: `.onepeloton${tld}`,
    ...initOptions,
  });
};

export type SavedClientOptions = Pick<Auth0ClientOptions, 'clientId' | 'domain'>;
export const OAUTH_CLIENT_KEY = 'OAUTH_CLIENT_OPTS';
/*
 * the client id is set to localStorage for later access by the universal-logout page
 */
const useClientOptions = (opts: SavedClientOptions) => {
  React.useEffect(() => {
    if (!!window) {
      try {
        window.localStorage.setItem(
          OAUTH_CLIENT_KEY,
          JSON.stringify({
            domain: opts.domain,
            clientId: opts.clientId,
          }),
        );
      } catch (e) {
        // catching in case stringify throws
      }
    }
  }, [opts.clientId, opts.domain]);
};

const OauthProvider: React.FC<React.PropsWithChildren<OauthProviderProps>> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  onErrorCallback,
  authEnv = AuthEnv.Prod,
  shouldUseRedirectPrevention = false,
  ...initOptions
}) => {
  // adding useRef to prevent calling handleRedirectCallback twice when component unmounts and remounts
  // see https://community.auth0.com/t/error-invalid-state-when-calling-handleredirectcallback-on-react-app/95329/2
  const shouldRedirect = React.useRef(true);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [userId, setUserId] = React.useState('');
  const [auth0Client, setAuth0] = React.useState<Auth0Client | undefined>(undefined);
  const [isLoading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [errorDescription, setErrorDescription] = React.useState('');
  useClientOptions(initOptions);

  React.useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = getAuth0Client(initOptions);

      if (
        !!window &&
        window?.location.search.includes('?code' || '&code') &&
        shouldRedirect.current
      ) {
        if (shouldUseRedirectPrevention) {
          shouldRedirect.current = false;
        }
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          await onRedirectCallback(appState);
        } catch (e) {
          setError(e);
          onErrorCallback?.(e, 'handleRedirectCallback');
        }
      }
      // Check for the presence of universal-login cookies to help prevent FSA (failed silent auth) errors in the BE
      // Although it's technically possible a user could have a valid session without this cookie, it's incredibly unlikely
      if (getContainsLoginCookie(authEnv)) {
        // https://github.com/auth0/auth0-spa-js#configure-the-sdk
        // getTokenSilently should be called to refresh the session when instantiating the SDK manually
        try {
          await auth0FromHook?.getTokenSilently();
        } catch {
          // Swallow the error from naively checking for a token on init
        }
      }

      try {
        const authenticated = await auth0FromHook.isAuthenticated();
        const tokenClaims = await auth0FromHook.getIdTokenClaims();
        const id = tokenClaims?.[userIdKey] || '';
        setIsAuthenticated(authenticated);
        setUserId(id);
      } catch (e) {
        setError(e);
        onErrorCallback?.(e, 'isAuthenticated');
      }

      setAuth0(auth0FromHook);
      setLoading(false);
    };
    initAuth0();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRedirectCallback = async () => {
    setLoading(true);
    if (!!auth0Client) {
      try {
        await auth0Client.handleRedirectCallback();
        setIsAuthenticated(true);
      } catch (e) {
        setError(e);
        onErrorCallback?.(e, 'handleRedirectCallback');
      }
      setLoading(false);
    }
  };

  /**
   * checkSession returns a 200 status if session is valid
   */
  const checkSession = async () => {
    const TLD = toMatchingTld(window.location.hostname);
    const api = authClient(authEnv, TLD);
    const token = (await auth0Client?.getTokenSilently()) ?? '';

    return api.get('/sso/check_session', {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const logout = async (opts: LogoutOptions, enableUniversalLogout = false) => {
    if (!auth0Client) {
      return Promise.resolve();
    }

    const tld = SupportedTLD.Com;
    const returnTo = `${authEnv}${tld}/sso/global_logout?continue=${encodeURIComponent(
      `${opts?.logoutParams?.returnTo}`,
    )}`;
    try {
      await auth0Client?.logout({
        ...opts,
        ...(opts?.logoutParams?.returnTo && enableUniversalLogout
          ? { logoutParams: { returnTo } }
          : {}),
      });
      setUserId('');
    } catch (e) {
      onErrorCallback?.(e, 'logout');
      return;
    }
  };

  const getIsAuthenticated = async () => {
    try {
      if (auth0Client) {
        const authenticated = await auth0Client.isAuthenticated();
        setIsAuthenticated(authenticated);
        return authenticated;
      } else {
        return new Promise(() => null);
      }
    } catch (e) {
      setError(e);
      setIsAuthenticated(false);
      onErrorCallback?.(e, 'isAuthenticated');
      return false;
    }
  };

  const setLoginError = (errorCode: string) => {
    setErrorDescription(errorCode);
  };

  const getLoginError = () => errorDescription;

  return (
    <Auth0Context.Provider
      value={{
        error,
        isAuthenticated,
        userId,
        isLoading,
        checkSession,
        logout,
        handleRedirectCallback,
        loginWithRedirect: (...p) =>
          (auth0Client ?? getAuth0Client(initOptions)).loginWithRedirect(...p),
        getIsAuthenticated,
        getAccessTokenSilently: (...p) =>
          !!auth0Client ? auth0Client.getTokenSilently(...p) : (Promise.resolve() as any),
        getIdTokenClaims: () =>
          !!auth0Client
            ? auth0Client.getIdTokenClaims()
            : Promise.resolve({ __raw: '-1' }),
        setLoginError,
        getLoginError,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export default OauthProvider;
